// // custom typefaces
// import "typeface-montserrat"
// import "typeface-merriweather"

// // normalize CSS across browsers
// import "./src/css/normalize.css"

// // custom CSS styles
// import "./src/css/style.css"

import React from "react"
import Layout from "./src/components/Layout"

import "bootstrap/dist/js/bootstrap.min.js"
import "@popperjs/core/dist/umd/popper.min.js"
import "./src/styles/bootstrap-overwrites.scss"

export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>
}
