import styled, { createGlobalStyle } from "styled-components"

const GlobalStyles = createGlobalStyle`

:root {
  --red: #FF4949;
  --bg-blue: rgba(59,59,69, 1); //#313953
  --bg-blue-transparet: rgba(74,82,96,,0.15);
}



body {
  background-color: var(--bg-blue);
  color: white;
}

.gatsby-image-wrapper img[src*="base64\\,"] {
   image-rendering: -moz-crisp-edges;
   image-rendering: crisp-edges;
}

button.state-playing {
  display: none !important;
}

.headroom {
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  position: fixed;
}

.headroom-wrapper {
  height: 0 !important;
}

.headroom--unfixed {
  @media (max-width: 768px) {
    position: fixed;
    transform: translateY(0);
  }
}

.headroom--scrolled {
  transition: transform 200ms ease-in-out;
}

.headroom--unpinned {
  @media (max-width: 768px) {
    position: fixed;
    transform: translateY(-100%);
  }
}

.headroom--pinned {
  @media (max-width: 768px) {
    position: fixed;
    transform: translateY(0%);
  }
}

.modal {
    .modal-dialog {
      border-radius: 0;
      max-width: 576px;
      border: 0;

      @media (max-width: 576px) {
        margin: 15px !important;
      }

    
      .modal-content {
        border: 0;
        border-radius: 0;
        min-height: 50vh;

        .modal-header {
          color: black;
          border: 0;
          padding: 15px;

          .btn-close {
            opacity: 1;
            width: 30px;
            height: 30px; 
            padding: 0;
            background-size: contain;
            margin: 0;
          }
        }

        .modal-body {
          padding: 15px;
          color: black;
        }
      }
    }
  }

`

export default GlobalStyles
