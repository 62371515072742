import React from "react"
import styled from "styled-components"

const StyledFooter = styled.footer`
  margin: auto 30px 0 30px;
  padding-top: 7px;
  padding-bottom: 25px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-top: 3px solid white;

  @media (max-width: 768px) {
    padding-bottom: 15px;
    margin: auto 15px 0 15px;
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }

  img {
    width: 180px;
    height: auto;
  }

  p {
    margin: 0;
    line-height: 1.3em;

    @media (max-width: ${props => props.theme.sizes.xs}) {
      font-size: 15px;
    }

    a {
      @media (hover: hover) {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
`

export default function Footer() {
  return (
    <StyledFooter>
      <div className="d-flex flex-column flex-sm-row">
        <p>
          <a
            href="https://goo.gl/maps/HehiX6LckU4Tdq7B8"
            target="_blank"
            rel="noreferrer"
          >
            Kanzleistrassse 126
            <br />
            8004 Zürich
          </a>
        </p>
        {/* <p className="ms-0 mt-3 mt-sm-0 ms-sm-14">
          <a href="mailto:mail@thierryseiler.ch">mail@thierryseiler.ch</a>
          <br />
          <a href="tel:0442234742">044 223 47 42</a>
        </p> */}
      </div>
      <div className="d-flex flex-column justify-content-start align-items-start mt-3 mt-md-0">
        <p className="text-right">
          <a href="mailto:mail@thierryseiler.ch">mail@thierryseiler.ch</a>
          <br />
          <a href="tel:0442234742">044 223 47 42</a>
        </p>
        {/* <p className="small mb-2">Mitglied bei:</p>
        <a href="https://www.swissmadesoftware.org/" target="_blank">
          <img src="/SMS-Logo-1h-white.svg" className="m-0" />
        </a> */}
      </div>
    </StyledFooter>
  )
}
