import { Helmet } from "react-helmet"
import styled, { ThemeProvider } from "styled-components"
import React from "react"
import Footer from "./Footer"
import Header from "./Header"
import "normalize.css"
import GlobalStyles from "../styles/GlobalStyles"
import Typography from "../styles/Typography"

const StyledMain = styled.main`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 175px;

  @media (max-width: 768px) {
    padding-top: 125px;
  }
`

const theme = {
  sizes: {
    xxs: "330px",
    xs: "576px",
    sm: "768px",
    md: "992px",
    lg: "1200px",
    xl: "1600px",
  },
}

export default function Layout({ children, location }) {
  return (
    <StyledMain>
      <Helmet>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        {/* <link
          href="https://fonts.googleapis.com/css2?family=Francois+One&display=swap"
          rel="stylesheet"
        /> */}
        <link
          href="https://fonts.googleapis.com/css2?family=Secular+One&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Typography />
      <GlobalStyles />
      <ThemeProvider theme={theme}>
        <Header location={location} />
        {children}
        <Footer />
      </ThemeProvider>
    </StyledMain>
  )
}
