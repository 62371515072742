exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktuell-js": () => import("./../../../src/pages/aktuell.js" /* webpackChunkName: "component---src-pages-aktuell-js" */),
  "component---src-pages-angebot-js": () => import("./../../../src/pages/angebot.js" /* webpackChunkName: "component---src-pages-angebot-js" */),
  "component---src-pages-archiv-js": () => import("./../../../src/pages/archiv.js" /* webpackChunkName: "component---src-pages-archiv-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-templates-single-projekt-js": () => import("./../../../src/templates/single-projekt.js" /* webpackChunkName: "component---src-templates-single-projekt-js" */)
}

